import React from 'react'
import PropTypes from 'prop-types'
import RichTextRenderer from '~/components/Content/RichText'

const ContentCallToAction = ({ content }) => {
  return <RichTextRenderer richText={content} />
}

ContentCallToAction.propTypes = {
  content: PropTypes.shape({})
}

export default ContentCallToAction
